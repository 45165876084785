import { TopicSectionNcssTree } from '@wheelroom/wheel-topic'
import { deepMerge, DeepPartial } from '@wheelroom/core'
import { iconTopicNcssNode } from '../../icon-ncss'
import { embedNcssNode } from '../../embed-ncss'
import { containerNcssNode, wrapperNcssNode } from '../../grid-ncss'

export const topicSectionGalleryNcssTree: DeepPartial<TopicSectionNcssTree> = {
  container: deepMerge([
    containerNcssNode,
    {
      ncss: {
        alignItems: 'stretch',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
  ]),
  topic: {
    ncssSwitch: {
      container: {
        p: 3,
        w: [1, 1 / 2],
        maxWidth: '35em',
      },
    },
    content: {
      ncss: {
        p: 3,
      },
    },
    icon: iconTopicNcssNode,
    embed: embedNcssNode,
    image: {
      picture: {
        ncss: {
          p: 3,
        },
      },
    },
  },
  wrapper: wrapperNcssNode,
}
