/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-training'
export const SvgTsnTraining = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-training_svg__st0{fill:#df0073}.tsn-training_svg__st2{clip-path:url(#tsn-training_svg__SVGID_2_);fill:#3d215c}'
        }
      </style>
      <path
        className="tsn-training_svg__st0"
        d="M88.9 139.6h-.3c-3-.3-5.9-.9-8.7-1.7-3.4-1-1.9-6.1 1.5-5.1 2.5.7 5.1 1.2 7.7 1.5 3.5.2 3.3 5.5-.2 5.3zm0 0"
      />
      <path
        d="M190.3 193h-.3l-24.7-2.6c-.6-.1-1.2-.3-1.6-.8l-5.8-5.8c-.5-.5-.8-1.2-.8-1.9v-7.8h-7.7c-1.5 0-2.7-1.2-2.7-2.7v-7.8H139c-1.5 0-2.7-1.2-2.7-2.7v-7.7h-7.8c-.7 0-1.4-.3-1.9-.8l-16-16c-.8.3-1.7.6-2.5.9-1.4.6-3-.2-3.5-1.7s.4-3 1.9-3.4c1.2-.4 2.5-.9 3.6-1.4 1-.4 2.2-.2 2.9.6l16.5 16.5h9.3c1.5 0 2.7 1.2 2.7 2.7v7.7h7.8c1.5 0 2.7 1.2 2.7 2.7v7.8h7.7c1.5 0 2.7 1.2 2.7 2.7v9.3l4.4 4.4 20.5 2.2-2.2-20.5-53.6-53.6c-.8-.8-1-1.9-.5-3 5.6-13.5 4.2-28.8-3.8-41S105.6 49.7 91 49.6h-.2c-11-.1-21.5 4.3-29.3 12.1-7.8 7.7-12.1 18.3-12 29.3 0 3.5.5 7 1.3 10.4.4 1.4-.5 2.9-1.9 3.2-1.4.4-2.9-.5-3.2-1.9-1-3.8-1.5-7.7-1.5-11.6-.1-12.5 4.8-24.4 13.5-33.2 8.7-8.8 20.7-13.7 33.1-13.6h.2c16.1.1 31 8.1 40.1 21.3 9.1 13.2 11.1 30.1 5.5 45.1l53 53.1c.4.4.7 1 .8 1.6l2.6 24.7c.2 1.5-1.1 2.9-2.7 2.9zm0 0"
        fill="#3d215c"
      />
      <path
        className="tsn-training_svg__st0"
        d="M64.1 129.4c-.6 0-1.2-.2-1.7-.6-1.3-1.1-2.5-2.2-3.7-3.4-.8-.8-1.6-1.6-2.3-2.5-1-1.1-.8-2.8.3-3.8 1.1-.9 2.8-.8 3.8.4.6.7 1.3 1.4 2 2.1 1.1 1.1 2.1 2 3.3 3 1.9 1.5.9 4.8-1.7 4.8zm0 0"
      />
      <defs>
        <path id="tsn-training_svg__SVGID_1_" d="M6.8 6.8h186.4v186.4H6.8z" />
      </defs>
      <clipPath id="tsn-training_svg__SVGID_2_">
        <use xlinkHref="#tsn-training_svg__SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        className="tsn-training_svg__st2"
        d="M79.2 92.4c-4.4 0-8.5-2.2-11-5.9s-2.9-8.3-1.2-12.4 5.3-7.1 9.6-7.9c4.3-.9 8.8.5 11.9 3.6 3.8 3.8 4.9 9.5 2.9 14.4-2.1 5-6.9 8.2-12.2 8.2zm0-21.2c-3.2 0-6.1 1.9-7.3 4.9-1.2 3-.5 6.4 1.7 8.6 3.1 3 8.1 3 11.2 0 2.3-2.3 2.9-5.7 1.7-8.6-1.2-2.9-4.1-4.8-7.3-4.9zm0 0"
      />
      <path
        className="tsn-training_svg__st2"
        d="M46.9 93.6h-.2C26.4 91.9 10 76.3 7.3 56.1c-2.7-20.2 9-39.5 28.1-46.5C54.6 2.6 76 9.8 87 27s8.5 39.7-5.9 54.1c-2.5 2.4-6.2-1.3-3.8-3.8 2.3-2.4 4.4-5 6-7.9 9.6-16.5 5.4-37.6-9.8-49.2S37 10 23.5 23.5C13 33.9 9.6 49.5 14.6 63.4 19.7 77.3 32.4 87 47.1 88.3c3.6.2 3.3 5.5-.2 5.3zm0 0"
      />
    </svg>
  )
}
