/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-agility'
export const SvgTsnAgility = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>{'.tsn-agility_svg__st3{fill:#3d215c}'}</style>
      <defs>
        <path id="tsn-agility_svg__SVGID_1_" d="M2.4 44.1h195.2v123.4H2.4z" />
      </defs>
      <clipPath id="tsn-agility_svg__SVGID_2_">
        <use xlinkHref="#tsn-agility_svg__SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        d="M159.6 98.3c-2.3 0-3.6-2.6-2.2-4.4l4.9-6.7c2.2-3 6.7.3 4.5 3.3l-4.9 6.7c-.5.7-1.4 1.1-2.3 1.1zm0 0"
        clipPath="url(#tsn-agility_svg__SVGID_2_)"
        fill="#df0073"
      />
      <path
        d="M116.8 157.2c-.5 0-1.1-.2-1.5-.5l-52.9-34.6c-1.8-1.1-1.7-3.7.2-4.8L158 64.5l-114 44c-.8.3-1.8.2-2.5-.3l-37.6-25c-2.1-1.4-1.4-4.6 1.1-5.1l189.2-33.4c.5-.1.9-.1 1.4.1.1 0 .2.1.3.1.6.3 1.1.7 1.3 1.3.3.6.4 1.2.3 1.9 0 .2-.1.4-.2.6-.1.2-.2.3-.3.5l-18.6 25.5c-2.2 3-6.7-.3-4.5-3.3L185 55.9 69.2 120l46.9 30.6 13.5-18.6c.9-1.2 2.6-1.5 3.9-.6 1.2.9 1.5 2.6.6 3.9L119 156.1c-.5.7-1.3 1.1-2.2 1.1zM12.7 82.3l30.6 20.4 123.5-47.6L12.7 82.3zm0 0"
        clipPath="url(#tsn-agility_svg__SVGID_2_)"
        fill="#3d215c"
      />
      <path
        d="M143.4 120.6c-2.3 0-3.6-2.6-2.2-4.4l4.8-6.5c.9-1.3 2.7-1.6 3.9-.7 1.3.9 1.5 2.7.6 3.9l-4.8 6.5c-.6.8-1.4 1.2-2.3 1.2zm0 0"
        fill="#df0073"
      />
      <path
        className="tsn-agility_svg__st3"
        d="M63.4 166.9c-1.2 0-2.2-.7-2.6-1.9l-20.5-58.3c-1.2-3.5 4-5.3 5.3-1.8l15.2 43.3.3-28.4c0-1.5 1.3-2.8 2.8-2.8 1.5 0 2.8 1.3 2.8 2.8l-.4 44.3c-.1 1.6-1.3 2.8-2.9 2.8zm0 0"
      />
      <path
        className="tsn-agility_svg__st3"
        d="M63.4 166.9c-2.6 0-3.8-3.2-1.8-4.9l29.8-25.1c1.2-1 2.9-.9 3.9.3s.8 3-.4 3.9l-29.7 25.1c-.5.5-1.1.7-1.8.7zm0 0"
      />
    </svg>
  )
}
