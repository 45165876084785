/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-selectionandcontracting'
export const SvgTsnSelectionandcontracting = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-selectionandcontracting_svg__st3,.tsn-selectionandcontracting_svg__st4{fill:#3d215c}.tsn-selectionandcontracting_svg__st4{clip-path:url(#tsn-selectionandcontracting_svg__SVGID_4_)}'
        }
      </style>
      <defs>
        <path
          id="tsn-selectionandcontracting_svg__SVGID_1_"
          d="M9.5 9.5h180.9v180.9H9.5z"
        />
      </defs>
      <clipPath id="tsn-selectionandcontracting_svg__SVGID_2_">
        <use
          xlinkHref="#tsn-selectionandcontracting_svg__SVGID_1_"
          overflow="visible"
        />
      </clipPath>
      <path
        d="M137.3 190.3h-7.5c-3.4 0-3.4-5.2 0-5.2h7.5c3.5 0 3.5 5.2 0 5.2zm0 0"
        clipPath="url(#tsn-selectionandcontracting_svg__SVGID_2_)"
        fill="#df0073"
      />
      <path
        d="M111.8 190.3H20.1c-5.7 0-10.3-4.6-10.3-10.3V20.1c0-5.7 4.6-10.3 10.3-10.3H180c5.7 0 10.3 4.6 10.3 10.3V180c0 5.1-3.8 9.5-8.8 10.2-3.4.5-4.1-4.6-.7-5.1 2.5-.4 4.4-2.5 4.4-5.1V20.1c0-2.8-2.3-5.2-5.2-5.2H20.1c-2.8 0-5.2 2.3-5.2 5.2V180c0 2.8 2.3 5.2 5.2 5.2h91.7c3.4-.1 3.4 5.1 0 5.1zm0 0"
        clipPath="url(#tsn-selectionandcontracting_svg__SVGID_2_)"
        fill="#3d215c"
      />
      <path
        d="M163.1 190.3h-7.7c-3.4 0-3.4-5.2 0-5.2h7.7c3.4 0 3.4 5.2 0 5.2zm0 0"
        fill="#df0073"
      />
      <path
        className="tsn-selectionandcontracting_svg__st3"
        d="M100 136.4c-1.4 0-2.6-1.2-2.6-2.6V66.2c0-3.4 5.2-3.4 5.2 0v67.6c0 1.4-1.1 2.6-2.6 2.6zm0 0"
      />
      <defs>
        <path
          id="tsn-selectionandcontracting_svg__SVGID_3_"
          d="M9.5 9.5h180.9v180.9H9.5z"
        />
      </defs>
      <clipPath id="tsn-selectionandcontracting_svg__SVGID_4_">
        <use
          xlinkHref="#tsn-selectionandcontracting_svg__SVGID_3_"
          overflow="visible"
        />
      </clipPath>
      <path
        className="tsn-selectionandcontracting_svg__st4"
        d="M100 190.3c-1.4 0-2.6-1.2-2.6-2.6v-30.5c0-3.4 5.2-3.4 5.2 0v30.5c0 1.4-1.1 2.6-2.6 2.6zm0 0M100 45.4c-1.4 0-2.6-1.2-2.6-2.6V12.3c0-3.4 5.2-3.4 5.2 0v30.5c0 1.4-1.1 2.6-2.6 2.6zm0 0M133.8 102.6H66.2c-3.4 0-3.4-5.2 0-5.2h67.6c3.5 0 3.5 5.2 0 5.2zm0 0M42.8 102.6H12.3c-3.4 0-3.4-5.2 0-5.2h30.5c3.5 0 3.5 5.2 0 5.2zm0 0M187.7 102.6h-30.5c-3.4 0-3.4-5.2 0-5.2h30.5c3.5 0 3.5 5.2 0 5.2zm0 0"
      />
      <path
        className="tsn-selectionandcontracting_svg__st3"
        d="M133.8 102.6c-.6 0-1.2-.2-1.7-.7-6.3-5.6-8.4-14.4-5.5-22.3 3-7.8 10.5-13 18.9-13 8.4 0 15.9 5.2 18.9 13 3 7.8.8 16.7-5.5 22.3-2.6 2.3-6-1.6-3.4-3.9 4.6-4.1 6.2-10.7 4-16.5-2.2-5.8-7.8-9.7-14-9.7s-11.8 3.8-14 9.7c-2.2 5.8-.6 12.4 4 16.5 1.8 1.7.7 4.6-1.7 4.6zm0 0M54.5 133.3c-11.1 0-20.2-9-20.2-20.2 0-5.8 2.5-11.2 6.8-15 2.6-2.1 5.8 1.6 3.4 3.9-3.2 2.8-5 6.9-5 11.2 0 8.3 6.7 15 15 15s15-6.7 15-15c0-4.3-1.8-8.3-5-11.2-2.4-2.3.8-5.9 3.4-3.9 4.3 3.8 6.8 9.3 6.8 15 0 11.2-9 20.2-20.2 20.2zm0 0M113.2 165.7c-5.8 0-11.2-2.5-15.1-6.8-.9-1.1-.8-2.7.2-3.6 1.1-.9 2.7-.8 3.6.2 4.1 4.7 10.7 6.3 16.6 4.1 5.8-2.2 9.7-7.8 9.7-14.1 0-6.2-3.9-11.8-9.7-14.1-5.8-2.2-12.4-.6-16.6 4.1-2.3 2.6-6.1-.8-3.9-3.4 4.6-5.1 11.4-7.5 18.1-6.5 6.8 1 12.5 5.4 15.3 11.7 2.8 6.2 2.2 13.5-1.5 19.2-3.5 5.7-9.9 9.2-16.7 9.2zm0 0M86.9 74.7c-6.8 0-13.2-3.5-16.9-9.2-3.7-5.7-4.3-13-1.5-19.2C71.3 40 77 35.6 83.8 34.6c6.8-1 13.6 1.4 18.1 6.5 2.3 2.6-1.6 6-3.9 3.4-4.2-4.6-10.7-6.2-16.6-4-5.8 2.2-9.7 7.8-9.7 14s3.8 11.8 9.7 14c5.8 2.2 12.4.6 16.6-4 2.3-2.6 6.1.8 3.9 3.4-3.8 4.3-9.2 6.8-15 6.8zm0 0"
      />
    </svg>
  )
}
