/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-transition'
export const SvgTsnTransition = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-transition_svg__st1{fill:#df0073}.tsn-transition_svg__st2,.tsn-transition_svg__st3{fill:#3d215c}.tsn-transition_svg__st3{clip-path:url(#tsn-transition_svg__SVGID_4_)}'
        }
      </style>
      <defs>
        <path
          id="tsn-transition_svg__SVGID_1_"
          d="M23.3 7.9h152.9v184.3H23.3z"
        />
      </defs>
      <clipPath id="tsn-transition_svg__SVGID_2_">
        <use xlinkHref="#tsn-transition_svg__SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        d="M136.6 110.6c-2.3 0-3.5-2.8-1.9-4.5L159 81.8c11.5-11.5 14.9-28.7 8.7-43.7a40.1 40.1 0 00-37.1-24.8c-2.4 0-4.8.2-7.2.7-1.4.3-2.8-.6-3.1-2.1-.3-1.4.7-2.8 2.2-3.1 14.7-2.7 29.7 2 40.3 12.5 17.7 17.7 17.7 46.4 0 64.2l-24.3 24.3c-.6.5-1.2.8-1.9.8zm0 0"
        clipPath="url(#tsn-transition_svg__SVGID_2_)"
        fill="#3d215c"
      />
      <path
        className="tsn-transition_svg__st1"
        d="M80.7 45.6c-2.3 0-3.5-2.8-1.9-4.5l5.4-5.4c2.5-2.4 6.1 1.2 3.7 3.7l-5.4 5.4c-.5.5-1.1.8-1.8.8zm0 0"
      />
      <path
        className="tsn-transition_svg__st2"
        d="M104 125.4c-12 0-23.6-4.8-32.1-13.3-.7-.7-1.5-1.5-2.2-2.3C56.4 94.4 54.8 72.1 66 55.1c1.9-2.9 6.3 0 4.4 2.9-9.9 15-8.5 34.8 3.2 48.4.6.7 1.2 1.4 1.9 2 7.5 7.6 17.7 11.8 28.4 11.8 5.3 0 10.5-1 15.4-3 2.7-1.2 5 2.2 2.9 4.3-.2.2-.5.4-.8.6-5.5 2.2-11.4 3.3-17.4 3.3zm0 0"
      />
      <path
        className="tsn-transition_svg__st1"
        d="M99 27.2c-2.3 0-3.5-2.8-1.9-4.5l1.3-1.3c1.5-1.5 3.1-2.9 4.8-4.2 1.2-.9 2.8-.6 3.7.6.9 1.2.6 2.8-.6 3.7-1.5 1.1-2.9 2.3-4.2 3.6l-1.3 1.3c-.4.5-1.1.8-1.8.8zm0 0"
      />
      <g>
        <defs>
          <path
            id="tsn-transition_svg__SVGID_3_"
            d="M23.3 7.9h152.9v184.3H23.3z"
          />
        </defs>
        <clipPath id="tsn-transition_svg__SVGID_4_">
          <use xlinkHref="#tsn-transition_svg__SVGID_3_" overflow="visible" />
        </clipPath>
        <path
          className="tsn-transition_svg__st3"
          d="M104.3 107.7c-7.4 0-14.5-2.9-19.7-8.2-.3-.3-.6-.6-.9-1-4.5-4.9-7.1-11.3-7.3-17.9v-.8c0-7.4 2.9-14.5 8.2-19.7l26-26.1c5.2-5.4 12.3-8.5 19.8-8.6 7.5-.1 14.7 2.9 20 8.2 5.3 5.3 8.2 12.5 8.2 20-.1 7.5-3.2 14.6-8.6 19.8l-20.4 20.4c-2.5 2.4-6.1-1.2-3.7-3.7l20.4-20.4c4.4-4.2 7-10 7-16.1.1-6.1-2.3-12-6.6-16.3-4.3-4.3-10.2-6.7-16.3-6.6-6.1.1-11.9 2.6-16.1 7l-26 26.1c-4.3 4.2-6.6 10-6.6 16v.4c.1 5.5 2.2 10.8 6 14.8l.3.3c.1.1.2.3.4.4 6.4 6.5 16.1 8.4 24.6 5 1.3-.5 2.9.1 3.4 1.4.5 1.3-.1 2.9-1.4 3.4-3.6 1.5-7.1 2.2-10.7 2.2zm0 0"
        />
        <path
          className="tsn-transition_svg__st3"
          d="M68.9 192C50.5 192 34 180.9 27 164c-7-17-3.1-36.5 9.8-49.5l24.3-24.3c1-1 2.7-1 3.7 0s1 2.7 0 3.7l-24.3 24.3c-7.8 7.5-12.2 17.8-12.3 28.5-.1 10.8 4.1 21.1 11.8 28.7 7.6 7.6 18 11.9 28.7 11.8 10.8-.1 21-4.5 28.5-12.3l26.6-26.6c10.4-10.4 14.3-25.6 10.1-39.7-1.6-5.5-4.4-10.6-8.2-15-.6-.7-1.2-1.4-1.9-2.1-7.5-7.6-17.7-11.8-28.4-11.8-5.3 0-10.6 1.1-15.5 3.1-3.2 1.3-5.2-3.5-2-4.9 5.5-2.3 11.5-3.5 17.5-3.5 12 0 23.6 4.8 32.1 13.3.8.8 1.5 1.6 2.2 2.4 4.3 4.9 7.4 10.7 9.3 16.9 4.6 15.9.2 33.1-11.5 44.9L101 178.7c-8.5 8.5-20 13.3-32.1 13.3zm0 0"
        />
      </g>
      <path
        className="tsn-transition_svg__st2"
        d="M69.2 174.2c-11.3 0-21.5-6.8-25.8-17.2-4.3-10.4-1.9-22.4 6.1-30.4l20.4-20.4c2.5-2.4 6.1 1.2 3.7 3.7l-20.4 20.4c-8.6 8.9-8.6 23 .2 31.8s22.9 8.8 31.8.2l26.1-26.1c4.3-4.2 6.6-10 6.6-16-.1-.3-.1-.7 0-1.1-.2-5.3-2.3-10.3-6-14.2l-.3-.3c-.1-.1-.2-.3-.4-.4-6.4-6.5-16.1-8.4-24.6-5-3.2 1.3-5.2-3.6-2-4.9 10.4-4.2 22.3-1.8 30.2 6.1.3.3.6.6.9 1 4.5 4.9 7.1 11.3 7.3 17.9V120.1c0 7.4-2.9 14.5-8.2 19.7L88.9 166c-5.2 5.2-12.3 8.2-19.7 8.2zm0 0"
      />
    </svg>
  )
}
