/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-socials'
export const SvgTsnSocials = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-socials_svg__st1,.tsn-socials_svg__st3{fill:#3d215c}.tsn-socials_svg__st3{clip-path:url(#tsn-socials_svg__SVGID_2_)}'
        }
      </style>
      <path
        d="M158.9 90.2c-1.5 0-2.7-1.2-2.7-2.7v-7.7c0-3.5 5.3-3.5 5.3 0v7.7c.1 1.5-1.1 2.7-2.6 2.7zm0 0"
        fill="#df0073"
      />
      <path
        className="tsn-socials_svg__st1"
        d="M151 191.6H60.4c-5.9 0-10.7-4.8-10.7-10.7v-165c0-5.9 4.8-10.7 10.7-10.7H151c5.9 0 10.7 4.8 10.7 10.7v45.3c0 3.5-5.3 3.5-5.3 0V15.9c0-2.9-2.4-5.3-5.3-5.3H60.4c-2.9 0-5.3 2.4-5.3 5.3V181c0 2.9 2.4 5.3 5.3 5.3H151c2.9 0 5.3-2.4 5.3-5.3v-48.2c0-3.5 5.3-3.5 5.3 0V181c0 5.8-4.8 10.6-10.6 10.6zm0 0"
      />
      <defs>
        <path id="tsn-socials_svg__SVGID_1_" d="M49.8 5h111.9v186.8H49.8z" />
      </defs>
      <clipPath id="tsn-socials_svg__SVGID_2_">
        <use xlinkHref="#tsn-socials_svg__SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        d="M158.9 116.7c-1.5 0-2.7-1.2-2.7-2.7v-7.9c0-3.5 5.3-3.5 5.3 0v7.9c.1 1.5-1.1 2.7-2.6 2.7zm0 0"
        clipPath="url(#tsn-socials_svg__SVGID_2_)"
        fill="#df0073"
      />
      <path
        className="tsn-socials_svg__st3"
        d="M158.9 39.6H52.4c-3.5 0-3.5-5.3 0-5.3h106.5c3.6-.1 3.6 5.3 0 5.3zm0 0M158.9 157.4H52.4c-3.5 0-3.5-5.3 0-5.3h106.5c3.6-.1 3.6 5.3 0 5.3zm0 0"
      />
      <path
        className="tsn-socials_svg__st1"
        d="M105.7 181.5c-3.9 0-7.4-2.4-8.9-6s-.7-7.8 2.1-10.5c2.8-2.8 6.9-3.6 10.5-2.1 3.6 1.5 6 5 6 8.9-.1 5.4-4.4 9.7-9.7 9.7zm0-14c-1.7 0-3.3 1.1-4 2.7-.7 1.6-.3 3.5.9 4.7 1.2 1.2 3.1 1.6 4.7.9 1.6-.7 2.7-2.2 2.7-4 0-2.4-1.9-4.3-4.3-4.3zm0 0M120.9 25H90.5c-3.5 0-3.5-5.3 0-5.3h30.4c3.5 0 3.5 5.3 0 5.3zm0 0M105.7 128.5c-13.2 0-25.1-8-30.2-20.2-5.1-12.2-2.3-26.3 7.1-35.6 9.3-9.3 23.4-12.1 35.6-7.1 12.2 5.1 20.2 17 20.2 30.2-.1 18-14.7 32.7-32.7 32.7zm0-60c-11.1 0-21 6.7-25.3 16.9-4.2 10.2-1.9 22 5.9 29.8 7.8 7.8 19.6 10.2 29.8 5.9 10.2-4.2 16.9-14.2 16.9-25.3 0-15.1-12.2-27.3-27.3-27.3zm0 0"
      />
      <path
        className="tsn-socials_svg__st1"
        d="M129.9 116.2c-.8 0-1.6-.4-2.1-1.1-5.1-7.1-13.4-11.2-22.1-11.2-8.7 0-16.9 4.2-22.1 11.2-2.1 2.8-6.3-.3-4.3-3.2 6.2-8.4 16-13.3 26.4-13.4 10.4 0 20.3 4.9 26.4 13.4 1.2 1.8 0 4.3-2.2 4.3zm0 0"
      />
      <path
        className="tsn-socials_svg__st1"
        d="M105.7 103.9c-4.9 0-9.4-3-11.3-7.5-1.9-4.6-.8-9.8 2.6-13.3 3.5-3.5 8.7-4.5 13.3-2.6 4.6 1.9 7.5 6.3 7.5 11.3.1 6.6-5.4 12.1-12.1 12.1zm0-19.1c-2.8 0-5.3 1.7-6.4 4.3-1.1 2.6-.5 5.5 1.5 7.5s4.9 2.6 7.5 1.5c2.6-1.1 4.3-3.6 4.3-6.4-.1-3.8-3.1-6.9-6.9-6.9zm0 0"
      />
    </svg>
  )
}
