import { IconMapType } from '@wheelroom/core'
import { SvgTsnAddress } from './tsn-address'
import { SvgTsnAgility } from './tsn-agility'
import { SvgTsnCoaching } from './tsn-coaching'
import { SvgTsnDynamicdelivery } from './tsn-dynamicdelivery'
import { SvgTsnMail } from './tsn-mail'
import { SvgTsnNetwork } from './tsn-network'
import { SvgTsnSelectionandcontracting } from './tsn-selectionandcontracting'
import { SvgTsnSocials } from './tsn-socials'
import { SvgTsnStrategy } from './tsn-strategy'
import { SvgTsnTraining } from './tsn-training'
import { SvgTsnTransition } from './tsn-transition'

export const tsnIconMap: IconMapType = {
  'tsn-address': SvgTsnAddress,
  'tsn-agility': SvgTsnAgility,
  'tsn-coaching': SvgTsnCoaching,
  'tsn-dynamicdelivery': SvgTsnDynamicdelivery,
  'tsn-mail': SvgTsnMail,
  'tsn-network': SvgTsnNetwork,
  'tsn-selectionandcontracting': SvgTsnSelectionandcontracting,
  'tsn-socials': SvgTsnSocials,
  'tsn-strategy': SvgTsnStrategy,
  'tsn-training': SvgTsnTraining,
  'tsn-transition': SvgTsnTransition,
}
