import { SectionWheelTheme } from '@wheelroom/core'

import { wrSystemTheme } from '../wr-system-theme'
import { light } from './colors/light'
import { data } from './data/data'
import { elementNcss } from './element-ncss/element-ncss'
import { styles } from './ncss-trees/styles'

export const tsnLight: SectionWheelTheme = {
  themeName: 'Tsn light',
  elementNcss,
  styles,
  data,
  wrSystemTheme: {
    ...wrSystemTheme,
    colorMap: light,
    fontMap: {
      text: `'Ubuntu', sans-serif`,
      heading: `'Ubuntu', sans-serif`,
      display: `'Ubuntu', sans-serif`,
      branding: `'Ubuntu', sans-serif`,
    },
  },
}

export const tsnDark: SectionWheelTheme = {
  themeName: 'Tsn dark',
  elementNcss,
  styles,
  data,
  wrSystemTheme: {
    ...wrSystemTheme,
    colorMap: light,
    fontMap: {
      text: `'Ubuntu', sans-serif`,
      heading: `'Ubuntu', sans-serif`,
      display: `'Ubuntu', sans-serif`,
      branding: `'Ubuntu', sans-serif`,
    },
  },
}
