/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-network'
export const SvgTsnNetwork = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-network_svg__st0{fill:#df0073}.tsn-network_svg__st0,.tsn-network_svg__st1{clip-path:url(#tsn-network_svg__SVGID_2_)}.tsn-network_svg__st1,.tsn-network_svg__st2{fill:#3d215c}'
        }
      </style>
      <defs>
        <path id="tsn-network_svg__SVGID_1_" d="M13 10.8h178.4v178.4H13z" />
      </defs>
      <clipPath id="tsn-network_svg__SVGID_2_">
        <use xlinkHref="#tsn-network_svg__SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        className="tsn-network_svg__st0"
        d="M74.5 184.5c-.3 0-.6 0-.8-.1-2.5-.9-5-1.8-7.3-2.8-1.3-.6-1.9-2.1-1.3-3.4.6-1.3 2.1-1.9 3.4-1.3 2.1 1 4.4 1.8 6.8 2.7 2.7.9 2.1 4.9-.8 4.9zm0 0"
      />
      <path
        className="tsn-network_svg__st1"
        d="M32.9 154.2c-.8 0-1.6-.4-2-1-11.5-15.3-17.7-34-17.7-53.2 0-29.6 14.8-57.3 39.4-73.9 24.6-16.5 55.8-19.8 83.3-8.6 1.3.5 1.9 2 1.4 3.3-.5 1.3-2 1.9-3.3 1.4-25.9-10.5-55.3-7.5-78.5 8.1C32.2 45.9 18.3 72.1 18.3 100c-.1 18.1 5.8 35.7 16.6 50.2 1.3 1.6.1 4-2 4zm0 0"
      />
      <path
        className="tsn-network_svg__st0"
        d="M51.9 172.9c-.5 0-1-.2-1.5-.5-2.2-1.6-4.2-3.2-6.1-4.8-1.1-.9-1.2-2.5-.3-3.6.9-1.1 2.6-1.2 3.6-.2 1.8 1.6 3.7 3.1 5.8 4.5 2 1.4 1 4.6-1.5 4.6zm0 0"
      />
      <path
        className="tsn-network_svg__st1"
        d="M102.2 189c-3.4 0-6.8-.2-10.2-.6-1.4-.1-2.4-1.4-2.3-2.8.2-1.4 1.5-2.4 2.9-2.2 26.5 3 52.9-6.7 71.1-26.3 18.2-19.5 26-46.6 21-72.8-.6-3.3 4.4-4.3 5-.9 4.9 26.1-2 52.9-18.9 73.4-16.9 20.4-42 32.2-68.6 32.2zm0 0"
      />
      <path
        className="tsn-network_svg__st2"
        d="M170.9 89.6c-16.9 0-32.4-9.3-40.4-24.2-8-14.9-7.1-32.9 2.2-47 1.9-2.8 6.1 0 4.2 2.8-8.3 12.5-9.1 28.5-2 41.8 7.1 13.2 20.9 21.5 35.9 21.5 5.3 0 10.5-1 15.3-3 3.1-1.3 5.1 3.4 1.9 4.7-5.3 2.3-11.2 3.4-17.1 3.4zm0 0M89.3 59.9c-7.2 0-13.3-5.4-14.2-12.5-.9-7.1 3.7-13.8 10.6-15.6 4-1 8.2-.3 11.6 2 3.4 2.3 5.6 6 6.1 10 .5 4.1-.8 8.2-3.5 11.3-2.6 3.1-6.5 4.8-10.6 4.8zM87 36.7c-3.6.9-6.3 3.9-6.8 7.6-.5 3.7 1.2 7.3 4.4 9.2 3.2 1.9 7.2 1.7 10.2-.6 3-2.2 4.3-6.1 3.4-9.7-1.3-4.8-6.3-7.8-11.2-6.5zm0 0M55.5 85.8c-5.1 0-9.9-2.8-12.4-7.2-2.5-4.5-2.5-9.9.1-14.4 2.6-4.4 7.3-7 12.4-7 5.1 0 9.8 2.8 12.3 7.2s2.5 9.9-.1 14.3c-2.5 4.4-7.2 7.1-12.3 7.1zm.1-23.5c-3.3 0-6.3 1.7-7.9 4.5C45.5 70.4 46.1 75 49 78c2.9 3 7.5 3.6 11.1 1.6 3.6-2.1 5.4-6.3 4.4-10.4-1.1-4.1-4.7-6.9-8.9-6.9zm0 0M50 127.9c-6.5 0-12.2-4.4-13.9-10.7-1.5-5.9.9-12.2 6-15.5 5.1-3.4 11.8-3.1 16.6.7 4.8 3.7 6.8 10.2 4.8 15.9-1.9 5.7-7.4 9.6-13.5 9.6zm-8.9-12c.9 3.6 3.9 6.3 7.6 6.8 3.7.5 7.3-1.2 9.2-4.4 1.9-3.2 1.7-7.2-.6-10.2-2.2-3-6.1-4.3-9.7-3.4-4.8 1.2-7.8 6.2-6.5 11.2zm0 0M75.8 161.6c-2.6 0-5.1-.7-7.3-2-5.9-3.5-8.5-10.6-6.2-17s8.7-10.4 15.5-9.4c6.8 1 11.9 6.5 12.3 13.4.4 6.8-4.1 13-10.7 14.7-1.2.1-2.4.3-3.6.3zm-4.6-6.4c3.8 2.2 8.6 1.5 11.6-1.8 2.9-3.3 3.1-8.2.5-11.7s-7.4-4.7-11.4-2.8c-4 1.9-6 6.3-4.9 10.6.5 2.5 2 4.5 4.2 5.7zm0 0M117.9 167.2c-7.6 0-13.9-5.9-14.3-13.4-.5-7.6 5-14.2 12.5-15.2s14.5 4.1 15.9 11.5c1.4 7.4-3.2 14.7-10.5 16.6-1.2.3-2.4.5-3.6.5zm.1-23.6c-2.8 0-5.5 1.3-7.3 3.6-1.7 2.3-2.4 5.2-1.6 7.9 1.3 4.9 6.3 7.9 11.2 6.6 4.5-1.2 7.4-5.5 6.8-10.1-.6-4.5-4.5-7.9-9.1-8zm0 0M151.6 141.3c-6.5 0-12.1-4.4-13.8-10.7-1.7-6.3 1.1-12.9 6.8-16.1 5.6-3.2 12.7-2.2 17.3 2.4 4.5 4.6 5.4 11.7 2.1 17.3-2.6 4.5-7.3 7.1-12.4 7.1zm.1-23.5c-.8 0-1.6.1-2.3.3-4.7 1.2-7.6 5.9-6.7 10.6.9 4.8 5.4 8 10.2 7.4 4.8-.6 8.3-4.8 8-9.7-.4-4.8-4.4-8.6-9.2-8.6zm0 0"
      />
    </svg>
  )
}
