/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-dynamicdelivery'
export const SvgTsnDynamicdelivery = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-dynamicdelivery_svg__st0{fill:#3d215c}.tsn-dynamicdelivery_svg__st1{fill:#df0073}.tsn-dynamicdelivery_svg__st2{clip-path:url(#tsn-dynamicdelivery_svg__SVGID_2_);fill:#3d215c}'
        }
      </style>
      <path
        className="tsn-dynamicdelivery_svg__st0"
        d="M102.8 183.3c-26.8-.3-52-12.9-68.3-34.3-16.2-21.4-21.7-49.1-14.7-75 .3-1.4 1.8-2.3 3.2-1.9 1.4.4 2.2 1.8 1.8 3.2-6.5 24.4-1.4 50.4 13.9 70.5s39 32 64.2 32.3c3.4 0 3.4 5.2-.1 5.2zm0 0M32.7 53.7c-2 0-3.3-2.3-2.2-4 3.4-5.3 7.3-10.2 11.8-14.6C58.6 18.8 80.6 9.6 103.7 9.6c23 0 45.1 9.1 61.4 25.4 2.3 2.4-1.2 6-3.6 3.7a81.68 81.68 0 00-57.7-23.9c-21.7 0-42.4 8.6-57.7 23.9-4.2 4.2-7.9 8.8-11.1 13.8-.6.7-1.5 1.2-2.3 1.2zm0 0M187.8 96.8c-1.4 0-2.5-1.1-2.6-2.5-.3-13.6-4.1-27-10.9-38.8-.8-1.2-.3-2.9.9-3.6 1.3-.7 2.9-.3 3.6 1 7.3 12.6 11.3 26.7 11.6 41.2.1 1.4-1.1 2.6-2.6 2.7zm0 0"
      />
      <path
        className="tsn-dynamicdelivery_svg__st1"
        d="M173.2 146.7c-2.1 0-3.3-2.3-2.1-4.1 1.4-2 2.6-4 3.8-6.2.7-1.3 2.3-1.7 3.5-1 1.3.7 1.7 2.3 1 3.5-1.3 2.3-2.7 4.5-4.1 6.6-.5.7-1.3 1.2-2.1 1.2zm0 0"
      />
      <path
        className="tsn-dynamicdelivery_svg__st0"
        d="M125.5 180.4c-3.1 0-3.6-4.5-.5-5.1 12.8-3.5 24.5-10 34.2-19 1-1 2.7-1 3.7.1s.9 2.7-.2 3.7c-10.3 9.6-22.9 16.6-36.5 20.2-.3.1-.5.1-.7.1zm0 0"
      />
      <path
        className="tsn-dynamicdelivery_svg__st1"
        d="M184.3 123.6c-.3 0-.5 0-.8-.1-1.4-.4-2.1-1.9-1.7-3.2.7-2.4 1.4-4.9 1.9-7.3.7-3.4 5.7-2.3 5.1 1-.6 2.8-1.2 5.4-2 7.8-.4 1.1-1.4 1.8-2.5 1.8zm0 0"
      />
      <defs>
        <path
          id="tsn-dynamicdelivery_svg__SVGID_1_"
          d="M2.5 9.5h188.1v181.1H2.5z"
        />
      </defs>
      <clipPath id="tsn-dynamicdelivery_svg__SVGID_2_">
        <use
          xlinkHref="#tsn-dynamicdelivery_svg__SVGID_1_"
          overflow="visible"
        />
      </clipPath>
      <path
        className="tsn-dynamicdelivery_svg__st2"
        d="M163.2 39.5h-19.9c-3.4 0-3.4-5.2 0-5.2h17.3V17c0-3.4 5.2-3.4 5.2 0v19.9c0 1.4-1.1 2.6-2.6 2.6zm0 0M32.2 94.4c-.9 0-1.8-.5-2.2-1.3l-8.6-15-15 8.6c-3 1.7-5.5-2.7-2.6-4.5L21 72.4c1.2-.7 2.8-.3 3.5.9l9.9 17.2c1.1 1.8-.2 3.9-2.2 3.9zm0 0"
      />
      <path
        className="tsn-dynamicdelivery_svg__st0"
        d="M142.7 190.4c-.5 0-.9-.1-1.3-.3l-17.2-9.9c-1.2-.7-1.7-2.3-.9-3.5l9.9-17.2c1.7-3 6.2-.4 4.5 2.6l-8.6 15 15 8.6c2.2 1.2 1.2 4.7-1.4 4.7zm0 0M108.6 154.2H97.3c-1.9 0-3.6-1.3-4.1-3.1l-2.1-7.6c-4.7-1.2-9.2-3-13.3-5.5l-6.9 3.9c-1.7.9-3.7.7-5.1-.7l-8.1-8c-1.3-1.3-1.6-3.4-.7-5.1l3.9-6.9c-2.5-4.1-4.3-8.6-5.5-13.3l-7.6-2.1c-1.8-.5-3.1-2.2-3.1-4.1V90.4c0-1.9 1.3-3.6 3.1-4.1l7.6-2.1c1.2-4.7 3-9.2 5.5-13.3L57 64c-.9-1.7-.7-3.7.7-5.1l8-8c1.3-1.3 3.4-1.6 5.1-.7l6.9 3.9c4.1-2.5 8.6-4.3 13.3-5.5l2.1-7.6c.5-1.9 2.2-3.1 4.1-3.1h11.4c1.9 0 3.6 1.3 4.1 3.1l2.1 7.6c4.7 1.2 9.2 3 13.3 5.5l6.9-3.9c1.7-.9 3.7-.7 5.1.7l8 8c1.3 1.3 1.6 3.4.7 5.1l-3.9 6.9c2.5 4.1 4.3 8.6 5.5 13.3l7.6 2.1c1.9.5 3.1 2.2 3.1 4.1v11.4c0 1.9-1.3 3.6-3.1 4.1l-7.6 2.1c-1.2 4.7-3 9.2-5.5 13.3l3.9 6.9c.9 1.6.7 3.7-.7 5.1l-8 8.1c-1.3 1.3-3.4 1.6-5.1.7l-6.9-3.9c-4.1 2.5-8.6 4.3-13.3 5.5l-2.1 7.6c-.5 1.6-2.1 2.9-4.1 2.9zM98 149.1h9.9l2.3-8.4c.3-.9 1-1.6 2-1.8 5.1-1.1 10-3.1 14.5-6 .8-.5 1.8-.5 2.7-.1l7.6 4.3 7-7-4.3-7.6c-.5-.8-.5-1.9.1-2.7 2.9-4.4 4.9-9.3 6-14.5.2-.9.9-1.7 1.8-2l8.4-2.3v-9.9l-8.4-2.3c-.9-.3-1.6-1-1.8-2-1.1-5.1-3.1-10-6-14.5-.5-.8-.5-1.8-.1-2.7L144 62l-7-7-7.6 4.3c-.8.5-1.9.5-2.7-.1-4.4-2.9-9.3-4.9-14.5-6-.9-.2-1.7-.9-2-1.8l-2.3-8.4H98l-2.3 8.4c-.3.9-1 1.6-2 1.8-5.1 1.1-10 3.1-14.5 6-.8.5-1.8.5-2.7.1L68.9 55l-7 7 4.3 7.6c.5.8.5 1.9-.1 2.7-2.9 4.4-4.9 9.3-6 14.5-.2.9-.9 1.7-1.8 2L50 91.2v9.9l8.4 2.3c.9.3 1.6 1 1.8 2 1.1 5.1 3.1 10 6 14.5.5.8.6 1.8.1 2.7L62 130l7 7 7.6-4.3c.8-.5 1.9-.5 2.7.1 4.4 2.9 9.3 4.9 14.5 6 .9.2 1.7.9 2 1.9l2.2 8.4zm0 0"
      />
      <path
        className="tsn-dynamicdelivery_svg__st0"
        d="M103 130.7c-14 0-26.6-8.4-32-21.4-5.4-12.9-2.4-27.8 7.5-37.7 9.9-9.9 24.8-12.9 37.7-7.5 12.9 5.4 21.4 18 21.4 32 0 19.1-15.5 34.6-34.6 34.6zm0-64c-11.9 0-22.7 7.2-27.2 18.2-4.6 11-2 23.7 6.4 32.1 8.4 8.4 21.1 10.9 32.1 6.4 11-4.6 18.2-15.3 18.2-27.2-.1-16.3-13.3-29.5-29.5-29.5zm0 0"
      />
    </svg>
  )
}
