/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-coaching'
export const SvgTsnCoaching = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>{'.tsn-coaching_svg__st3{fill:#3d215c}'}</style>
      <defs>
        <path id="tsn-coaching_svg__SVGID_1_" d="M38.4 6h123.2v183.8H38.4z" />
      </defs>
      <clipPath id="tsn-coaching_svg__SVGID_2_">
        <use xlinkHref="#tsn-coaching_svg__SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        d="M138.3 25.7c-.6 0-1.2-.2-1.7-.6-1.9-1.6-3.9-3.1-6-4.5-1.2-.8-1.6-2.4-.8-3.7.8-1.2 2.5-1.6 3.7-.7 2.3 1.5 4.5 3.1 6.6 4.9 1.8 1.6.7 4.6-1.8 4.6zm0 0"
        clipPath="url(#tsn-coaching_svg__SVGID_2_)"
        fill="#df0073"
      />
      <path
        d="M128.7 141.8H71.3c-1.4 0-2.6-1.2-2.6-2.6v-1.6c0-12.4-3.7-16.6-11-24.9-1.5-1.7-3.1-3.5-4.9-5.6C37.1 88.3 34 62 45 40.1S78.9 4.9 103.4 6.3c3.8.1 7.6.7 11.3 1.7 3.3.9 2.1 5.9-1.3 5.1-3.4-.9-6.8-1.4-10.3-1.5-22.4-1.2-43.4 10.9-53.4 30.9-10.1 20-7.3 44.1 7.1 61.3 1.7 2 3.3 3.9 4.8 5.5 7.2 8.2 12.1 13.7 12.3 27.3h52.3c.2-13.7 4.8-18.9 12.4-27.4 1.4-1.6 2.9-3.3 4.5-5.2 8.8-10.4 13.5-23.6 13.3-37.2 0-.4 0-.8-.1-1.2-.4-3.5 4.9-4 5.2-.5.1.6.1 1.2.1 1.7.2 14.9-4.9 29.3-14.5 40.6-1.6 1.9-3.2 3.7-4.6 5.3-7.4 8.3-11 12.5-11 24.9v1.6c-.1 1.4-1.3 2.6-2.8 2.6zm0 0"
        clipPath="url(#tsn-coaching_svg__SVGID_2_)"
        fill="#3d215c"
      />
      <path
        d="M153.9 46.8c-1 0-2-.6-2.4-1.6-1-2.3-2.2-4.6-3.5-6.7-1.6-2.9 2.6-5.5 4.5-2.8 1.5 2.4 2.8 4.8 3.9 7.4.6 1.3 0 2.9-1.3 3.5-.4.2-.8.2-1.2.2zm0 0"
        fill="#df0073"
      />
      <path
        className="tsn-coaching_svg__st3"
        d="M138 160.4H62c-1.5 0-2.9-.6-3.9-1.6s-1.6-2.4-1.6-3.9V142c0-1.5.6-2.9 1.6-3.9s2.4-1.6 3.9-1.6h76c1.5 0 2.9.6 3.9 1.6s1.6 2.4 1.6 3.9v12.8c0 3.1-2.5 5.6-5.5 5.6zm-76-18.6c-.1 0-.3.1-.3.3v12.8c0 .1.1.3.3.3h76c.1 0 .3-.1.3-.3v-12.8c0-.1-.1-.3-.3-.3H62zm0 0"
      />
      <path
        className="tsn-coaching_svg__st3"
        d="M117.9 189.6H82.2c-5.4 0-9.7-4.3-9.7-9.7v-22.1c0-1.4 1.2-2.6 2.6-2.6h49.8c1.4 0 2.6 1.2 2.6 2.6v22.1c0 5.3-4.3 9.7-9.6 9.7zm-40.2-29.2v19.5c0 2.5 2 4.5 4.5 4.5h35.7c1.2 0 2.3-.5 3.1-1.3.8-.8 1.3-2 1.3-3.1v-19.5H77.7zm0 0"
      />
      <path
        className="tsn-coaching_svg__st3"
        d="M140.6 175h-15.7c-3.5 0-3.5-5.2 0-5.2h15.7c3.5-.1 3.5 5.2 0 5.2zm0 0M75.1 175H59.4c-3.5 0-3.5-5.2 0-5.2h15.7c3.5-.1 3.5 5.2 0 5.2zm0 0M100 141.8c-1.4 0-2.6-1.2-2.6-2.6V94c0-3.5 5.2-3.5 5.2 0v45.2c0 1.4-1.2 2.6-2.6 2.6zm0 0"
      />
      <path
        className="tsn-coaching_svg__st3"
        d="M100 96.6c-.7 0-1.4-.3-1.9-.8L73.3 71c-1.1-1-1.1-2.7 0-3.7 1-1 2.7-1 3.7 0l23 23 23-23c1-1.1 2.7-1.1 3.7 0 1 1 1 2.7 0 3.7l-24.9 24.9c-.4.5-1.1.7-1.8.7zm0 0"
      />
    </svg>
  )
}
