import { TopicSectionNcssTree } from '@wheelroom/wheel-topic'
import { DeepPartial } from '@wheelroom/core'
import { wrapperNcssNode } from '../../grid-ncss'
import {
  primaryButtonNcssNode,
  displayButtonNcssNode,
  secondaryButtonNcssNode,
} from '../../../element-ncss/button-ncss'
import { embedNcssNode } from '../../embed-ncss'
import { iconButtonNcssNode, iconTopicNcssNode } from '../../icon-ncss'

export const tsnSectionCarouselNcssTree: DeepPartial<TopicSectionNcssTree> = {
  container: {
    ncss: {
      display: 'flex',
      MsOverflowStyle: 'none',
      overflowX: 'scroll',
      scrollbarWidth: 'none',
      scrollSnapType: 'x mandatory',
      w: 1,
      WebkitOverflowScrolling: 'touch',
      mx: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  topic: {
    ncssSwitch: {
      container: {
        scrollSnapAlign: 'center',
        scrollSnapStop: 'always',
        w: 1,
        minWidth: 1,
      },
    },
    media: {
      ncss: {
        maxWidth: [1, '48em'],
        mx: 'auto',
      },
    },
    content: {
      ncss: {
        maxWidth: [1, '48em'],
        mx: 'auto',
        p: 3,
        pb: 1,
        pt: 5,
        textAlign: 'center',
      },
    },
    actionGroup: {
      ncss: {
        mt: 3,
      },
    },
    action: {
      ncss: {
        ...primaryButtonNcssNode.ncss,
        ...displayButtonNcssNode.ncss,
        ':nth-of-type(2)': {
          ...secondaryButtonNcssNode.ncss,
          ...displayButtonNcssNode.ncss,
        },
      },
      icon: iconButtonNcssNode,
    },
    abstract: {
      ncss: {
        fontSize: [5, 5, 6, 6],
      },
    },
    icon: iconTopicNcssNode,
    embed: embedNcssNode,
  },
  wrapper: wrapperNcssNode,
}
