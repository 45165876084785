import React from 'react'
import { DeepPartial } from '@wheelroom/core'
import { NavigationSectionData } from '@wheelroom/wheel-navigation'

export const navigationSectionData: DeepPartial<NavigationSectionData> = {
  header: {
    hideActions: false,
    hideBranding: false,
    hideMenu: false,
    hideModal: false,
    hideSocial: true,
    hideSkipToContent: false,
    hideThemeButton: true,
    /**
     * Example:
     * import React from 'react
     * useLogoElement: <img src={'images/logo.png'} width={50} height={50} />,
     */
    useLogoElement: (
      <img src={'/images/tsn-logo.svg'} width={108} height={46} />
    ),
  },
  footer: {
    copyright: `© Copyright ${new Date().getFullYear()} The Sourcing Network. All rights reserved.`,
    hideBrand: true,
    hideCard: false,
    hideCopyright: false,
    hideLegal: false,
    hideMenu: false,
    hideSocial: false,
    // Thanks for using Wheelroom! We appreciate your support.
    supportWheelroom: true,
    useLogoElement: (
      <img src={'/images/tsn-logo.svg'} width={108} height={46} />
    ),
  },
  sitemap: {
    headingElementName: 'h4',
    hideMenu: false,
    hideSegmentHeading: false,
    maxSegments: 16,
  },
}
