import {
  SectionWheels,
  // supportsDarkMode,
  wrSystemConfig,
} from '@wheelroom/core'

import { tsnDark, tsnLight } from '../themes/tsn/tsn'

export const sectionWheels: SectionWheels = {
  defaultThemeId: 'tsnLight',
  themes: {
    tsnLight,
    tsnDark,
  },
  wrSystemConfig,
}
