/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-strategy'
export const SvgTsnStrategy = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-strategy_svg__st0{fill:#3d215c}.tsn-strategy_svg__st1{fill:#df0073}'
        }
      </style>
      <path
        className="tsn-strategy_svg__st0"
        d="M100 165.6c-1.2 0-2.1-.9-2.1-2.1v-127c0-2.8 4.2-2.8 4.2 0v127c0 1.2-.9 2.1-2.1 2.1zm0 0"
      />
      <path
        className="tsn-strategy_svg__st0"
        d="M163.5 102.1h-127c-2.8 0-2.8-4.2 0-4.2h127c2.8 0 2.8 4.2 0 4.2zm0 0"
      />
      <path
        className="tsn-strategy_svg__st0"
        d="M116.4 60.5c16 6.6 26.4 22.2 26.4 39.5v1.1c1.4.1 2.8.2 4.2.5V100c0-19-11.5-36.2-29-43.4-17.6-7.3-37.8-3.3-51.2 10.2-13.5 13.4-17.5 33.6-10.2 51.2 7.3 17.6 24.4 29 43.4 29 6.5 0 12.9-1.3 18.8-3.9 2.5-1.2.8-4.9-1.7-3.9-5.4 2.4-11.2 3.6-17.1 3.6-17.3 0-32.9-10.4-39.5-26.4-6.6-16-3-34.4 9.3-46.6 12.2-12.3 30.6-16 46.6-9.3z"
      />
      <path
        className="tsn-strategy_svg__st1"
        d="M134.5 132.8c-1.9.2-3.1-2-1.8-3.5s2.4-3 3.5-4.7c.6-1 1.9-1.3 2.9-.7 1 .6 1.3 2 .6 3-1.2 1.8-2.4 3.6-3.9 5.2-.3.4-.8.7-1.3.7zm0 0"
      />
      <path
        className="tsn-strategy_svg__st0"
        d="M100 132c-13 0-24.6-7.8-29.6-19.8s-2.2-25.8 6.9-34.9c9.2-9.2 22.9-11.9 34.9-6.9S132 87 132 100c0 17.7-14.3 32-32 32zm0-59.8c-11.3 0-21.4 6.8-25.7 17.2-4.3 10.4-1.9 22.4 6 30.3 8 8 19.9 10.3 30.3 6 10.4-4.3 17.2-14.4 17.2-25.7 0-15.4-12.4-27.8-27.8-27.8zm0 0M171.9 66c-1.2 0-2.1-.9-2.1-2.1V30.2h-33.7c-2.8 0-2.8-4.2 0-4.2H172c1.2 0 2.1.9 2.1 2.1V64c0 1.1-1 2-2.2 2zm0 0M28.1 66c-1.2 0-2.1-.9-2.1-2.1V28.1c0-1.2.9-2.1 2.1-2.1H64c2.8 0 2.8 4.2 0 4.2H30.2v33.7c0 1.2-1 2.1-2.1 2.1zm0 0M171.9 174.1H136c-2.8 0-2.8-4.2 0-4.2h33.7v-33.7c0-2.8 4.2-2.8 4.2 0v35.9c.2 1-.8 2-2 2zm0 0M63.9 174.1H28.1c-1.2 0-2.1-.9-2.1-2.1v-35.9c0-2.8 4.2-2.8 4.2 0v33.7h33.7c2.8 0 2.8 4.3 0 4.3zm0 0"
      />
      <path
        className="tsn-strategy_svg__st1"
        d="M143.1 116.8c-1.7.8-3.6-.9-2.9-2.7.7-1.8 1.3-3.7 1.8-5.6.2-1.2 1.4-1.9 2.6-1.6 1.2.3 1.9 1.4 1.6 2.6-.5 2.1-1.1 4.2-1.9 6.2-.3.4-.7.8-1.2 1.1zm0 0"
      />
      <path
        className="tsn-strategy_svg__st0"
        d="M117.9 142.8c-1.8-.7-1.8-3.2 0-3.9 1.8-.7 3.6-1.6 5.2-2.6 1-.6 2.3-.3 2.9.7.6 1 .2 2.3-.8 2.9-1.9 1.1-3.8 2.1-5.8 2.9-.5.1-1 .2-1.5 0zm0 0"
      />
    </svg>
  )
}
