/** @jsx jsx */
import { SVGProps } from 'react'
import { jsx } from '@emotion/core'
import { styledSystem } from '@wheelroom/styled-system'
import { Wheel } from '@wheelroom/core'
interface IconProps extends SVGProps<SVGSVGElement> {
  ncss: any;
  wheel: Wheel;
  strokeWidth: number;
}
const componentId = 'svg-tsn-mail'
export const SvgTsnMail = (props: IconProps) => {
  const strokeWidth = props.strokeWidth || 2
  const css = styledSystem(
    props.wheel.wrSystemConfig,
    props.wheel.wrSystemTheme,
    props.ncss
  )
  return (
    <svg
      id={componentId}
      x={0}
      y={0}
      viewBox="0 0 200 200"
      xmlSpace="preserve"
      css={css}
      stroke="currentColor"
      strokeWidth={strokeWidth}
      width="100%"
    >
      <style>
        {
          '.tsn-mail_svg__st0,.tsn-mail_svg__st2,.tsn-mail_svg__st3{fill:#3d215c}.tsn-mail_svg__st2,.tsn-mail_svg__st3{clip-path:url(#tsn-mail_svg__SVGID_2_)}.tsn-mail_svg__st3{fill:#df0073}'
        }
      </style>
      <path
        className="tsn-mail_svg__st0"
        d="M181 183.1h-33.8c-3.3 0-3.3-4.9 0-4.9h31.3V79.8c0-3.3 4.9-3.3 4.9 0v100.9c.1 1.3-1 2.4-2.4 2.4zm0 0"
      />
      <path d="M105.5 183.1h-7.2c-3.3 0-3.3-4.9 0-4.9h7.2c3.3 0 3.3 4.9 0 4.9zm0 0" />
      <path
        className="tsn-mail_svg__st0"
        d="M81.1 183.1H13.7c-1.4 0-2.5-1.1-2.5-2.5V79.8c0-3.3 4.9-3.3 4.9 0v98.4H81c3.4 0 3.4 4.9.1 4.9zm0 0"
      />
      <defs>
        <path id="tsn-mail_svg__SVGID_1_" d="M11.1 10.8h172.6v172.6H11.1z" />
      </defs>
      <clipPath id="tsn-mail_svg__SVGID_2_">
        <use xlinkHref="#tsn-mail_svg__SVGID_1_" overflow="visible" />
      </clipPath>
      <path
        d="M130 183.1h-7.3c-3.3 0-3.3-4.9 0-4.9h7.3c3.3 0 3.3 4.9 0 4.9zm0 0"
        clipPath="url(#tsn-mail_svg__SVGID_2_)"
      />
      <path
        className="tsn-mail_svg__st2"
        d="M13.7 82.3c-2.3 0-3.4-2.9-1.5-4.4L38 57.4c2.6-2 5.6 1.8 3.1 3.9L15.3 81.7c-.5.4-1 .6-1.6.6zm0 0M181 82.3c-.6 0-1.1-.2-1.5-.5l-25.8-20.5c-2.5-2 .5-5.9 3.1-3.9l25.8 20.5c1.8 1.4.7 4.4-1.6 4.4zm0 0M126.3 38.8c-.6 0-1.1-.2-1.5-.5L97.4 16.5 70 38.3c-1.1.9-2.6.7-3.5-.4-.8-1.1-.7-2.6.4-3.5l28.9-23c.9-.7 2.2-.7 3.1 0l28.9 23c1.9 1.5.8 4.4-1.5 4.4zm0 0"
      />
      <path
        className="tsn-mail_svg__st2"
        d="M97.4 148.7c-.6 0-1.1-.2-1.5-.5L12.2 81.7c-2.5-2 .5-5.9 3.1-3.9L97.4 143l82.1-65.2c2.6-2 5.6 1.8 3.1 3.9L99 148.1c-.5.4-1.1.6-1.6.6zm0 0"
      />
      <path
        className="tsn-mail_svg__st3"
        d="M130 183.1h-7.3c-3.3 0-3.3-4.9 0-4.9h7.3c3.3 0 3.3 4.9 0 4.9zm0 0"
      />
      <path
        className="tsn-mail_svg__st2"
        d="M81.1 183.1H13.7c-2.2 0-3.3-2.8-1.7-4.3L71.2 125c1-1 2.6-1 3.6.1.9 1 .8 2.7-.3 3.6l-54.4 49.6h61c3.3-.1 3.3 4.8 0 4.8zm0 0"
      />
      <path
        className="tsn-mail_svg__st3"
        d="M105.5 183.1h-7.2c-3.3 0-3.3-4.9 0-4.9h7.2c3.3 0 3.3 4.9 0 4.9zm0 0"
      />
      <path
        className="tsn-mail_svg__st2"
        d="M181 183.1h-33.8c-3.3 0-3.3-4.9 0-4.9h27.5l-54.4-49.6c-1-.9-1.1-2.5-.2-3.5.9-1 2.5-1.1 3.5-.2l59.1 53.9c1.6 1.6.6 4.3-1.7 4.3zm0 0"
      />
      <path
        className="tsn-mail_svg__st0"
        d="M155.2 102.8c-1.4 0-2.5-1.1-2.5-2.5V38.8H42v61.5c0 3.3-4.9 3.3-4.9 0v-64c0-1.4 1.1-2.5 2.5-2.5h115.6c1.4 0 2.5 1.1 2.5 2.5v64c-.1 1.4-1.2 2.5-2.5 2.5zm0 0"
      />
      <path
        className="tsn-mail_svg__st0"
        d="M135.5 58.9H59.2c-3.3 0-3.3-4.9 0-4.9h76.3c3.3 0 3.3 4.9 0 4.9zm0 0M135.5 77.8H59.2c-3.3 0-3.3-4.9 0-4.9h76.3c3.3 0 3.3 4.9 0 4.9zm0 0M135.5 96.6H59.2c-3.3 0-3.3-4.9 0-4.9h76.3c3.3 0 3.3 4.9 0 4.9zm0 0"
      />
    </svg>
  )
}
