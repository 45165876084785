import { TopicSectionNcssTree } from '@wheelroom/wheel-topic'
import { deepMerge, DeepPartial } from '@wheelroom/core'
import { containerNcssNode, wrapperNcssNode } from '../../grid-ncss'

export const tsnSectionLogosNcssTree: DeepPartial<TopicSectionNcssTree> = {
  container: deepMerge([
    containerNcssNode,
    {
      ncss: {
        justifyContent: 'center',
        p: 3,
        flexWrap: 'wrap',
      },
    },
  ]),
  topic: {
    media: {
      ncss: {
        w: [1 / 3, 1 / 5, 1 / 7],
      },
    },
    image: {
      ncss: {
        objectFit: 'cover',
        w: 1,
      },
      picture: {
        ncss: {
          display: 'block',
          m: 'auto',
          p: 3,
        },
      },
    },
  },
  wrapper: wrapperNcssNode,
}
