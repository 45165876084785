import { baseColors } from './base-colors'

export const light = {
  ...baseColors,

  heading: baseColors.magenta,
  text: baseColors.darkGrey,
  link: baseColors.purple,
  border: baseColors.purpleLight,
  outline: baseColors.magenta,

  dividerBg: baseColors.purpleLight,

  skipToContentBg: baseColors.purple,
  skipToContentText: baseColors.white,
  skipToContentOutline: baseColors.magenta,

  sectionBg: baseColors.white,
  sectionText: baseColors.darkGrey,
  sectionBorder: baseColors.purpleLight,

  buttonPrimaryBg: baseColors.magenta,
  buttonPrimaryBgState: baseColors.magentaShade,
  buttonPrimaryBorder: baseColors.magentaShade,
  buttonPrimaryText: baseColors.white,

  buttonSecondaryBg: 'transparent',
  buttonSecondaryBorder: baseColors.magenta,
  buttonSecondaryBorderState: baseColors.magentaLight,
  buttonSecondaryText: baseColors.magenta,

  cardBorder: baseColors.silver,
  cardShadow: baseColors.silver,

  modalBg: baseColors.white,
  modalBorder: baseColors.bullet,
  modalShadow: baseColors.bullet,

  iconColor: baseColors.magentaLight,
  iconColorState: baseColors.magentaShade,

  codeBg: baseColors.purpleLight,
}
